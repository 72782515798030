import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, from } from "rxjs";
import { map, share } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth.service";
import { ZenApiService } from "../../../services/zen-rpc-service";
import { InferredRequests } from "@zixi/zen-rpc";
import { LinodeAccount } from "@zixi/models";

@Injectable({
    providedIn: "root"
})
export class LinodeAccountsService {
    linodeAccounts: Observable<LinodeAccount[]>;
    private linodeAccounts$: ReplaySubject<LinodeAccount[]>;
    private dataStore: {
        linodeAccounts: LinodeAccount[];
    };

    constructor(private authService: AuthService, private zenApi: ZenApiService, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            linodeAccounts: []
        };
        this.linodeAccounts$ = new ReplaySubject(1) as ReplaySubject<LinodeAccount[]>;
        this.linodeAccounts = this.linodeAccounts$.asObservable();
    }

    private prepLinodeAccount(linodeAccount: LinodeAccount) {
        return linodeAccount;
    }

    private updateStore(linodeAccount: LinodeAccount, merge: boolean): void {
        this.prepLinodeAccount(linodeAccount);

        const currentIndex = this.dataStore.linodeAccounts.findIndex(g => g.id === linodeAccount.id);
        if (currentIndex === -1) {
            this.dataStore.linodeAccounts.push(linodeAccount);
            return;
        } else if (merge) {
            const current = this.dataStore.linodeAccounts[currentIndex];

            Object.assign(current, linodeAccount);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.linodeAccounts[currentIndex] = linodeAccount;
        }
    }

    getAccount(id: number): Observable<LinodeAccount> {
        const linodeAccount$ = from(this.zenApi.client.linodeAccount.get({ params: { id } })).pipe(share());

        return linodeAccount$.pipe(map(r => r.body.result));
    }

    refreshLinodeAccounts(): Observable<LinodeAccount[]> {
        const linodeAccounts$ = from(this.zenApi.client.linodeAccount.getList()).pipe(share());

        linodeAccounts$.subscribe({
            next: data => {
                const linodeAccounts = data.body.result;

                this.dataStore.linodeAccounts.forEach((existing, existingIndex) => {
                    const newIndex = linodeAccounts.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.linodeAccounts.splice(existingIndex, 1);
                });

                linodeAccounts.forEach(tp => this.updateStore(tp, true));

                this.linodeAccounts$.next(Object.assign({}, this.dataStore).linodeAccounts);
            },
            // eslint-disable-next-line no-console
            error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        });

        return linodeAccounts$.pipe(map(r => r.body.result));
    }

    async addLinodeAccount(params: InferredRequests["linodeAccount"]["create"]) {
        try {
            const result = await this.zenApi.client.linodeAccount.create(params);
            if (result.status !== 200) return false;

            const linodeAccount = result.body.result;
            this.updateStore(linodeAccount, false);
            this.linodeAccounts$.next(Object.assign({}, this.dataStore).linodeAccounts);
            return linodeAccount;
        } catch (error) {
            return false;
        }
    }

    async deleteLinodeAccount(id: number): Promise<boolean> {
        try {
            const result = await this.zenApi.client.linodeAccount.destroy({ params: { id } });

            if (result.status !== 200) return false;

            const deletedId = result.body.result;

            const index = this.dataStore.linodeAccounts.findIndex(a => a.id === deletedId);
            if (index !== -1) this.dataStore.linodeAccounts.splice(index, 1);

            this.linodeAccounts$.next(Object.assign({}, this.dataStore).linodeAccounts);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateLinodeAccount(params: InferredRequests["linodeAccount"]["update"]) {
        try {
            const result = await this.zenApi.client.linodeAccount.update(params);

            if (result.status !== 200) return false;
            const updatedLinodeAccount = result.body.result;
            this.updateStore(updatedLinodeAccount, true);

            this.linodeAccounts$.next(Object.assign({}, this.dataStore).linodeAccounts);

            return updatedLinodeAccount;
        } catch (error) {
            return false;
        }
    }
}
